import React from 'react';
import { Box, Typography } from '@mui/material';

const Account = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Аккаунт
      </Typography>
      <Typography variant="body1">
        Имя: {user ? user.name : 'N/A'}
      </Typography>
      <Typography variant="body1">
        Email: {user ? user.email : 'N/A'}
      </Typography>
    </Box>
  );
};

export default Account;

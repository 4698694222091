import React, { useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import emailjs from 'emailjs-com';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const AuthDialog = ({ registerOpen, handleRegisterClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const resetFields = () => {
    setEmail('');
    setPassword('');
    setCode('');
    setConfirmationCode('');
    setIsCodeSent(false);
    setIsLogin(true);
  };

  const handleSendCode = () => {
    const existingUsers = JSON.parse(localStorage.getItem('users')) || [];
    const userExists = existingUsers.some(user => user.email === email);

    if (userExists) {
      toast.error('Пользователь с таким Email уже зарегистрирован.');
      return;
    }

    const generatedCode = Math.floor(100000 + Math.random() * 900000).toString();
    setConfirmationCode(generatedCode);

    emailjs.send('service_nbxpwbf', 'template_ygswe5o', {
      user_email: email,
      confirmation_code: generatedCode
    }, 'IIA3Ac47_738vF0UH')
    .then(() => {
      setIsCodeSent(true);
      toast.success('Код подтверждения отправлен на ваш email.');
    })
    .catch(error => {
      console.error('Ошибка при отправке кода подтверждения:', error);
      toast.error('Ошибка при отправке кода подтверждения.');
    });
  };

  const handleRegister = () => {
    const existingUsers = JSON.parse(localStorage.getItem('users')) || [];
    const userExists = existingUsers.some(user => user.email === email);

    if (userExists) {
      toast.error('Пользователь с таким Email уже зарегистрирован.');
      return;
    }

    if (code === confirmationCode) {
      toast.success('Регистрация прошла успешно!');
      const newUser = { email, password };
      localStorage.setItem('users', JSON.stringify([...existingUsers, newUser]));
      setUser(newUser);
      localStorage.setItem('user', JSON.stringify(newUser));
      resetFields();
      handleRegisterClose();
      navigate('/profile');
    } else {
      toast.error('Неверный код подтверждения.');
    }
  };

  const handleLogin = () => {
    const existingUsers = JSON.parse(localStorage.getItem('users')) || [];
    const user = existingUsers.find(user => user.email === email && user.password === password);
    if (user) {
      toast.success('Вход выполнен успешно!');
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      resetFields();
      handleRegisterClose();
      navigate('/profile');
    } else {
      toast.error('Неверный email или пароль.');
    }
  };

  return (
    <Dialog open={registerOpen} onClose={() => { handleRegisterClose(); resetFields(); }} maxWidth="sm" fullWidth>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
      <DialogTitle>{isLogin ? 'Вход' : 'Регистрация'}</DialogTitle>
      <DialogContent>
        <>
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Пароль"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {isCodeSent && !isLogin && (
            <TextField
              label="Код подтверждения"
              fullWidth
              margin="normal"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          )}
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { handleRegisterClose(); resetFields(); }} color="primary">
          Отмена
        </Button>
        {!isCodeSent && !isLogin && (
          <Button onClick={handleSendCode} color="primary">
            Отправить код
          </Button>
        )}
        {isLogin ? (
          <Button onClick={handleLogin} color="primary">
            Войти
          </Button>
        ) : (
          isCodeSent && (
            <Button onClick={handleRegister} color="primary">
              Зарегистрироваться
            </Button>
          )
        )}
        <Button onClick={() => setIsLogin(!isLogin)} color="secondary">
          {isLogin ? 'Зарегистрироваться' : 'Войти'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthDialog;

import React, { useEffect, useState, useContext } from 'react';
import { Container, Typography, List, ListItem, ListItemText } from '@mui/material';
import { UserContext } from '../contexts/UserContext';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const allOrders = JSON.parse(localStorage.getItem('orders')) || [];
    const userOrders = allOrders.filter(order => order.userEmail === user.email);
    setOrders(userOrders);
  }, [user]);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Ваши заказы
      </Typography>
      <List>
        {orders.map((order, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`Заказ: ${order.carName}`}
              secondary={`Итоговая цена: ${order.totalPrice} Адрес: ${order.address} Время заказа: ${order.orderTime}`}
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default Orders;

import React, { useState } from 'react';
import { Box, Container, Typography, List, ListItem, ListItemText, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Account from './Account';
import Orders from './Orders';

const ProfilePage = () => {
  const [selectedSection, setSelectedSection] = useState('account');
  const navigate = useNavigate();

  const renderContent = () => {
    switch (selectedSection) {
      case 'account':
        return <Account />;
      case 'orders':
        return <Orders />;
      default:
        return <Account />;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/');
    window.location.reload();
  };

  return (
    <Container sx={{ display: 'flex', marginTop: 4 }}>
      <Box sx={{ width: '20%', borderRight: '1px solid #ccc', paddingRight: 2 }}>
        <Typography variant="h6" gutterBottom>
          Профиль
        </Typography>
        <List component="nav">
          <ListItem button selected={selectedSection === 'account'} onClick={() => setSelectedSection('account')}>
            <ListItemText primary="Аккаунт" />
          </ListItem>
          <ListItem button selected={selectedSection === 'orders'} onClick={() => setSelectedSection('orders')}>
            <ListItemText primary="Заказы" />
          </ListItem>
        </List>
        <Button 
          variant="contained" 
          color="error" 
          onClick={handleLogout}
          sx={{ marginTop: 2 }}
        >
          Выйти из аккаунта
        </Button>
      </Box>
      <Box sx={{ flexGrow: 1, paddingLeft: 2 }}>
        {renderContent()}
      </Box>
    </Container>
  );
};

export default ProfilePage;
